<template>
	<div class="page">
		<van-nav-bar title="申请服务费" left-arrow border fixed placeholder safe-area-inset-top @click-left="onClickLeft" />
		<div class="pt">
			<div class="list" @click="onCooperation">
				<div class="list-left">合作方式</div>
				<div class="list-right">
					<div class="text-orange">
						<span v-if="state.co_mode==1">个人</span>
						<span v-if="state.co_mode==2">企业 </span>
						<span v-if="state.co_mode==3">个体工商户</span>
						<span v-if="state.co_status!=1"> 一 </span>
						<span v-if="state.co_status==0">审核中</span>
						<!-- <span v-if="state.co_status==1">
							<span v-if="state.co_mode==1">已认证</span>
							<span v-else>已签约</span>
						</span> -->
						<span v-if="state.co_status==2">
							<span v-if="state.co_mode==1">认证失败，请修改信息</span>
							<span v-else>认证失败，请修改信息</span>
						</span>
					</div>
					<van-icon name="arrow" class="text-gray" />
				</div>
			</div>
			<div class="list" @click="onCard">
				<div class="list-left">收款账户</div>
				<div class="list-right">
					<div class="text-gray pr">{{cardText}}</div>
					<van-icon name="arrow" class="text-gray" />
				</div>
			</div>
			<div class="list" @click="tourl">
				<div class="list-left">申请记录</div>
				<div class="list-right">
					<div class="text-gray pr">查看申请记录</div>
					<van-icon name="arrow" class="text-gray" />
				</div>
			</div>
		</div>
		<div class="service-charge" v-if="state.co_status==1" @click="onServiceCharge">去申请服务费</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				openid: null,
				login_token: localStorage.getItem("login_token"),
				state: '', //签约状态
				cardText: '暂无账户',
				can_apply:0,/* 是否可以申请服务费 */
			}
		},
		mounted() {
			var _this = this;
// 获取用户的合作方式及认证/签约状态
					_this.$toast.loading({
						message: '加载中...',
						forbidClick: true,
						duration: 0
					});
					var myData = new URLSearchParams();
					myData.append("openid", _this.openid);
					myData.append("login_token", _this.login_token);
					this.$http({
						method: 'post',
						url: '/users/api.finance/getUserModeInfo',
						data: myData
					}).then((res) => {
						_this.$toast.clear();
						if (res.data.code == 0) {
							_this.state = res.data.data;
							_this.can_apply = res.data.data.can_apply
							if(res.data.data.co_status==1 || res.data.data.co_status==0){
								_this.$toast.loading({
									message: '加载中...',
									forbidClick: true,
									duration:0
								});
								//获取用户收款账户
								var myCard = new URLSearchParams();
								myCard.append("openid", _this.openid);
								myCard.append("login_token", _this.login_token);
								myCard.append("is_default", "1");
								_this.$http({
									method: 'post',
									url: 'users/api.finance/getUserBankInfo',
									data: myCard
								}).then((res) => {
									_this.$toast.clear();
									if (res.data.code == 0) {
										if(res.data.data){
											var cardNum=res.data.data.account_num
											cardNum=cardNum.substr(cardNum.length-4);
											_this.cardText="尾号"+cardNum
										}
									} else if (res.data.code == -100) {
										_this.$dialog.alert({
											title: '提示',
											message: '登录过期，请重新登录',
										}).then(() => {
											_this.$router.push({
												path: "/login"
											})
										});
								
									} else {
										_this.$toast(res.data.msg);
									}
								}).catch(() => {
									_this.$toast.clear();
									_this.$toast('网络请求失败');
								});
							}
						} else if (res.data.code == -100) {
							_this.$dialog.alert({
								title: '标题',
								message: '登录过期，请重新登录',
							}).then(() => {
								_this.$router.push({
									path: "/login"
								})
							});

						} else {
							_this.$toast(res.data.msg);
						}
					}).catch(() => {
						_this.$toast.clear();
						_this.$toast('网络请求失败');
					});
		},
		methods: {
			tourl(){
				var _this = this;
				_this.$router.push({
					path: "/applyRecord",
					query: {
						co_mode: _this.state.co_mode
					}
				})
			},
			//返回
			onClickLeft() {
				console.log(window.history.length)
				if (window.history.length <= 1) {
					this.$router.push({
						path: '/my'
					})
					return false
				} else {
					this.$router.go(-1)
				}
			},
			//进入合作方式
			onCooperation() {
				var _this = this;
				var tatusqg = '';
				if (_this.state.co_mode == 1) { //无发票
					tatusqg = 2
				} else if (_this.state.co_mode == 2) { //企业
					tatusqg = 0
				} else if (_this.state.co_mode == 3) { //个体工商户
					tatusqg = 1
				}
				if (_this.state.co_status == 0) { //审核中
					_this.$router.push({
						path: "/realname/cooperation/explain",
						query: {
							choice: tatusqg,
							state: 1, //审核中按钮隐藏
						}
					})
				} else if (_this.state.co_status == 1) { //已认证
					_this.$router.push({
						path: "/realname/cooperation/list",
						query: {
							choice: tatusqg
						}
					})
				} else if (_this.state.co_status == 2) { //签约失败
					_this.$router.push({
						path: "/realname/cooperation/authentication",
						query: {
							modify: 1, //进入修改
							choice: tatusqg
						}

					})
				}

			},
			//去申请服务费
			onServiceCharge() {
				var _this = this;
				if(this.can_apply){
					_this.$router.push({
						path: "/apply",
						query: {
							co_mode: _this.state.co_mode
						}
					})
				}else{
					this.$toast("该服务暂未开放")
				}

			},
			//收款账户
			onCard() {
				var _this=this;
				if(_this.state.co_status==0){
					_this.$toast("认证成功后方可进入");
					_this.$router.push({
						path:"/realname/card/account",
						query:{
							co_mode:_this.state.co_mode,//1 个人 2 企业 3 个体工商户
						}
					})
				}else if(_this.state.co_status==1){
					_this.$router.push({
						path:"/realname/card/account",
						query:{
							co_mode:_this.state.co_mode,//1 个人 2 企业 3 个体工商户
						}
					})
					
				}else if(_this.state.co_status==2){
					
					_this.$toast("认证成功后方可进入");
				}
			},

		},
		created(){
			this.openid = this.$store.state.openid
		}
	}
</script>

<style scoped>
	.service-charge {
		width: 6.16rem;
		height: 0.96rem;
		background-color: #F15223;
		color: #FFFFFF;
		font-size: 0.36rem;
		line-height: 0.96rem;
		text-align: center;
		border-radius: 0.08rem;
		margin: 0 auto;
		margin-top: 1.2rem;
	}

	.page {
		width: 100%;
		height: 100%;
		background: #f6f6f6;
	}

	.pt {
		padding-top: 0.3rem;
	}

	.list {
		width: 100%;
		height: 1.1rem;
		background-color: #FFFFFF;
		display: flex;
		justify-content: space-between;
		font-size: 0.26rem;
		align-items: center;
	}

	.list~.list {
		width: 100%;
		height: 1.1rem;
		background-color: #FFFFFF;
		display: flex;
		justify-content: space-between;
		border-top: 0.02rem solid #f0eeee;
		align-items: center;
	}

	.list-left {
		padding-left: 0.3rem;
	}

	.list-right {
		display: flex;
		padding-right: 0.3rem;
		align-items: flex-end;
	}

	.text-orange {
		color: #f25d31;
	}

	.text-gray {
		color: #d7d6d6;
	}

	.pr {
		padding-right: 0.2rem;
	}
</style>
